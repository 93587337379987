<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-tep-estado"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Modificar Autorización</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                id="estado"
                class="form-control form-control-sm"
                v-model="form_estado.estado"
                :class="
                  $v.form_estado.estado.$invalid ? 'is-invalid' : 'is-valid'
                "
                @change="getEstadoName"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in $parent.listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.form_estado.estado.required">
                Seleccione un estado
              </div>
            </div>
            <div class="form-group">
              <label for="observacion">Observaciones</label>
              <textarea
                v-model="form_estado.observacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form_estado.observacion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form_estado.observacion.required">
                Ingrese una Observación
              </div>
            </div>
            <div class="form-group">
              <label for="sign_password">Contraseña</label>
              <div class="input-group m-0">
                <div class="input-group-prepend">
                  <div class="input-group-text p-0">
                    <a class="btn btn-sm" @click="showPassword()">
                      <i :class="btnText"></i>
                    </a>
                  </div>
                </div>
                <input
                  :type="type_pass"
                  class="form-control form-control-sm"
                  v-model="form_estado.password"
                  :class="
                    $v.form_estado.password.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form_estado.$invalid"
              v-if="$store.getters.can('cs.autorizaciones.autorizacion')"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
export default {
  name: "CsAutorizacionEstado",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      form_estado: {},
      type_pass: "password",
      btnText: 'fas fa-eye',
    };
  },
  validations: {
    form_estado: {
      estado: {
        required,
      },
      password: {
        required,
      },
      observacion: {
        required,
      },
    },
  },
  methods: {
    save() {
      if (!this.$v.form_estado.$invalid) {
        axios({
          method: "PUT",
          url: "/api/cs/AutorizacionEstado",
          data: this.form_estado,
        })
          .then((response) => {
            this.cargando = false;
            if (response.data.autorizado != null) {
              this.form_estado = {};
              this.$refs.closeModal.click();
              this.$parent.getIndex();
              this.$swal({
                icon: "success",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              });
            } else {
              this.$swal({
                icon: "error",
                title: response.data.coincidir,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          });
        this.$parent.getIndex();
      }
    },
    llenar_modal_estado(aut) {
      this.form_estado = {
        id: aut.id,
        estado: aut.estado,
        nEstado: aut.nEstado,
        observacion: aut.observaciones,
        usuario_autoriza_id: this.$store.state.user.id,
        solicitud_type: aut.cs_solicitud_type,
        solicitud_id: aut.cs_solicitud_id,
      };
    },
    getEstadoName(){
      let estado = this.$parent.listasForms.estados.find((e) => e.numeracion === this.form_estado.estado);
      this.form_estado.nEstado = estado.descripcion;
    },
    showPassword() {
      if (this.type_pass === "password") {
        this.type_pass = "text";
        this.btnText = 'fas fa-lock';
      } else {
        this.type_pass = "password";
        this.btnText = 'fas fa-eye';
      }
    },
  },
  async mounted() {
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
